import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BookTemplate from '../templates/book-template'

const Index = (props) => <BookTemplate {...props} />

Index.propTypes = {
  data: PropTypes.object.isRequired
}

export default Index

export const pageQuery = graphql`
  query indexFrQuery {
    settings: allContentfulSettings(
      filter: { node_locale: { eq: "fr-CA" } }
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
    features: allContentfulFeature(
      limit: 3,
      filter: { node_locale: { eq: "fr-CA" } }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...FeatureLink
        }
      }
    }
  }
`
